import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "table-dateGoodList"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_yz_table = _resolveComponent("yz-table");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_yz_table, {
    tableLabel: _ctx.tabledateGoodListConfig,
    dataList: _ctx.filterSearch2,
    funArray: _ctx.funArray
  }, null, 8, ["tableLabel", "dataList", "funArray"])]);
}