import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import { computed, defineComponent, ref, watch } from 'vue';
import yzTable from '@/base-ui/table';
import { ElMessage } from 'element-plus';
import { datePickerFormat } from '@/utils/data-format';
import { tabledateGoodListConfig } from '../config/dateGoodList.config';
import { useStore } from '@/store';
import yday from '@/utils/day';
import { deleteGood } from '@/service/main/goods/dateGoodList/dateGoodList';
export default defineComponent({
  props: {
    serchFiled: {
      type: Object,
      required: true
    },
    testSearch: {
      type: String,
      default: ''
    }
  },
  components: {
    yzTable: yzTable
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var today = yday.today();
    store.dispatch('goods/dateGoodListAction', today);
    var dateFoodList = computed(function () {
      return store.state.goods.dateGoodList;
    });
    var serachDate = ref('');
    watch(props.serchFiled, function (newValue) {
      serachDate.value = datePickerFormat(newValue.date);
      store.dispatch('goods/dateGoodListAction', serachDate.value);
    });
    var funArray = [function (index, row) {
      var id = row.id;
      deleteGood(id).then(function (res) {
        if (res.status === 200) {
          ElMessage({
            type: 'success',
            message: '删除商品成功'
          });
          emit('clearSearch');
          store.dispatch('goods/clearDateGoodListAction');
        } else {
          ElMessage({
            type: 'error',
            message: '删除商品失败' + res.data
          });
        }
      });
    }];
    var filterSearch = computed(function () {
      var list = dateFoodList.value.filter(function (data) {
        var result = data.orderDate.split(' ')[0].toLowerCase().includes(serachDate.value === null ? '' : serachDate.value);
        return result;
      });
      return list;
    });
    var filterSearch2 = computed(function () {
      var list = filterSearch.value.filter(function (data) {
        return props.serchFiled.type === 0 || props.serchFiled.type === data.type;
      });
      return list;
    });
    return {
      filterSearch2: filterSearch2,
      dateFoodList: dateFoodList,
      tabledateGoodListConfig: tabledateGoodListConfig,
      funArray: funArray
    };
  }
});