import type { ITableLabel } from '@/base-ui/table/types'

import type { dateGoodListProp } from '../types/types'

const tabledateGoodListConfig: ITableLabel<dateGoodListProp>[] = [
  {
    type: 'id',
    label: '排序',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '名称',
    prop: 'name',
    isSearchFields: true,
    options: {
      align: 'center'
    },
    popover: [
      {
        title: '商品名称',
        prop: 'name'
      },
      {
        title: '商品id',
        prop: 'foodId'
      }
    ]
  },
  {
    type: 'normal',
    label: '预约时间',
    prop: 'orderDate',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '价格',
    prop: 'price',
    prefix: '￥',
    tag: {
      type: 'warning'
    },
    options: {
      align: 'center'
    }
  },
  {
    type: 'image',
    label: '图片',
    prop: 'imgUrl',
    imgStyle: {
      width: '60px',
      height: '60px'
    },
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '创建日期',
    prop: 'createAt',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    prop: 'type',
    label: '类型',
    ifPropFields: {
      ifProp: '1',
      value1: {
        text: '早餐',
        tagOptions: {
          type: 'success',
          effect: 'dark'
        }
      },
      value2: {
        text: '午餐',
        tagOptions: {
          type: 'warning',
          effect: 'dark'
        }
      }
    },
    tag: {
      type: 'success',
      effect: 'dark'
    },
    options: {
      align: 'center'
    }
  },
  {
    type: 'operations',
    headerItem: {
      type: 'header',
      placeholder: '商品搜索......',
      options: {
        size: 'small'
      }
    },
    operationsOption: [
      {
        value: '删除',
        btnAttribute: {
          type: 'danger',
          size: 'small'
        },
        popconfirm: {
          title: '确认删除该商品?',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      }
    ]
  }
]

export { tabledateGoodListConfig }
