import { defineComponent, ref } from 'vue';
import tableDateGoodList from '@/components/main/dateGoodList';
import { getDatePickerTimeStampArray } from '@/utils/data-format';
export default defineComponent({
  components: {
    tableDateGoodList: tableDateGoodList
  },
  setup: function setup() {
    var serchFiled = ref({
      date: '',
      type: 0
    });

    var clearSearch = function clearSearch() {
      serchFiled.value.date = '';
    };

    var search = ref('');
    var shortcuts = getDatePickerTimeStampArray();
    var options = [{
      value: 0,
      label: '全部'
    }, {
      value: 1,
      label: '早餐'
    }, {
      value: 2,
      label: '午餐'
    }];
    return {
      search: search,
      shortcuts: shortcuts,
      options: options,
      serchFiled: serchFiled,
      clearSearch: clearSearch
    };
  }
});